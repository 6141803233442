document.querySelector('.nav--burger').addEventListener("click", function( e ) {
    e.preventDefault();

    const menuBtn = document.querySelector('.nav--burger');
    const navigationMenu = document.querySelector('.nav--primary');

    menuBtn.classList.toggle('active');
    document.body.classList.toggle('overflow-hidden');

    navigationMenu.classList.toggle('-translate-y-full');
    navigationMenu.classList.add('duration-300');

}, false);

window.addEventListener('resize', function() {
     const navigationMenu = document.querySelector('.nav--primary');
     navigationMenu.classList.remove('duration-300');
});
