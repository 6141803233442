// Import our CSS
import styles from '../css/app.pcss';
import "../../node_modules/@fancyapps/ui/dist/fancybox.css";
import "../css/vendors/fancybox.pcss";

/* ------------------------------------------------
CORE:
Load any critical scripts first, including functionality
that exists on every page.
------------------------------------------------*/
import burgerMenu from './modules/navigationMenu.js';

// Lazy Load images
import LazyLoad from "vanilla-lazyload";
const lazyContent = new LazyLoad({});


function changeMainContentPadding(){
    let hdr = document.getElementsByClassName('hdr');
    let content = document.getElementsByTagName('body');

    if(window.innerWidth > 1023){
        content[0].style.paddingTop = 0;
    }
    else{
        if(content[0] && hdr[0]) content[0].style.paddingTop = hdr[0].offsetHeight+'px';
    }
}

window.addEventListener('resize', function(){
   changeMainContentPadding();
});

changeMainContentPadding();


// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
